/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import { object, bool, string } from 'prop-types';
import { useHistory, useLocation } from 'react-router';
import { compose } from 'recompose';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import mbpUtil from 'mbp-api-util';

import { trackPage } from '../../../helpers/tracking/common/commonTrackingHelpers';
import { isContentPageRoute } from '../../../../routes/contentPageRoutes';
import RedirectWithStatus from '../GraphqlCommonComponents/RedirectWithStatus/RedirectWithStatus';
import PageErrorBoundary from '../../PageErrorBoundary/PageErrorBoundary';
import FrequentlyAskedQuestions from '../CustomerService/CustomerServiceFaqPage/FrequentlyAskedQuestions';
import { getActiveABTests } from '../../../../state/ducks/App/ducks/ABTesting/ABTesting-Selectors';
import { redirectAbTesting, getRedirectAbTest } from '../../../helpers/ABTesting/redirectAbTestingHelper';
import { getFeatureFlag } from '../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { getBrandName, getBrandIdentifier } from '../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import useStyleLinkTag from '../../EnterpriseDesignSystem/StyleLinkContext/useStyleLinkTag';

const LoadableHomePage = loadable(() => import(/* webpackChunkName: "GraphqlHomePage" */ '../GraphqlHomePage/GraphqlHomePage'));
const LoadableGraphqlDepartmentPage = loadable(() => import(/* webpackChunkName: "GraphqlDepartmentPage" */ '../GraphqlDepartmentPage/GraphqlDepartmentPage'));
const LoadableGraphqlUniversalLander = loadable(() => import(/* webpackChunkName: "UniversalLander" */ '../UniversalTemplate/Templates/Lander/LanderContainer'));
const LoadableCardIsleTemplate = loadable(() => import(/* webpackChunkName: "CardIsleTemplate" */ '../CardIsleTemplate/CardIsleTemplateQuery'));
const LoadableGraphqlRecipePage = loadable(() => import(/* webpackChunkName: "GraphqlRecipePage" */ '../GraphqlRecipePage/GraphqlRecipePage'));
const LoadableGraphqlClubPage = loadable(() => import(/* webpackChunkName: "GraphqlClubPage" */ '../GraphqlClubPage/GraphqlClubPage'));
const LoadableCouponPageContainer = loadable(() => import(/* webpackChunkName: "CouponPageContainer" */ '../CouponPage/CouponPageContainer'));
const LoadableGraphqlRecipeCategoryLanderPage = loadable(() => import(/* webpackChunkName: "GraphqlRecipeCategoryLanderPage" */ '../GraphqlRecipePage/GraphqlRecipeCategoryLanderPage'));
const LoadableContentPageBuilder = loadable(() => import(/* webpackChunkName: "ContentPageBuilder" */ '../ContentPageBuilder/ContentPageBuilder'));
const LoadableConnectPage = loadable(() => import(/* webpackChunkName: "ConnectPage" */ '../ConnectPage/ConnectPage'));
const LoadableGraphqlCategoryPageContainer = loadable(() => import(/* webpackChunkName: "GraphqlCategoryPageContainer" */ '../GraphqlCategoryPage/GraphqlCategoryPageContainer'));
const LoadableGraphqlTemplatePageContainer = loadable(() => import(/* webpackChunkName: "GraphqlTemplatePageContainer" */ '../GraphqlTemplatePage/GraphqlTemplatePageContainer'));
const LoadableSeoArticlesPage = loadable(() => import(/* webpackChunkName: "SeoArticlesPage" */ '../SeoArticlesPage/SeoArticlesPage'));
const LoadablePassportPageContainer = loadable(() => import(/* webpackChunkName: "PassportPage" */ '../PassportPage/PassportPage'));
const LoadableProductNotAvailableContainer = loadable(() => import(/* webpackChunkName: "ProductNotAvailableContainer" */ '../GraphqlProductPage/Partials/ProductNotAvailable/ProductNotAvailableContainer'));
const LoadableGraphqlProductPageContainer = loadable(() => import(/* webpackChunkName: "GraphqlProductPageContainer" */ '../GraphqlProductPage/GraphqlProductPageContainer'));
const LoadableGraphqlContentPage = loadable(() => import(/* webpackChunkName: "GraphqlContentPage" */ '../GraphqlContentPage/GraphqlContentPage'));
const LoadableAddOnsABContainer = loadable(() => import(/* webpackChunkName: "AddOnsABContainer" */ '../GraphqlAddOnsPage/AddOnsABContainer'));
const LoadableWrapUpABContainer = loadable(() => import(/* webpackChunkName: "WrapUpABContainer" */ '../WrapUpPage/WrapUpABContainer'));
const LoadableConnectionCommunitiesPage = loadable(() => import(/* webpackChunkName: "ConnectionCommunitiesPage" */ '../ConnectionCommunitiesPage/ConnectionCommunitiesPage'));
const LoadableEcardsPage = loadable(() => import(/* webpackChunkName: "EcardsPage" */ '../EcardsPage/EcardsPage'));
const LoadableGraphqlWyngStaticPage = loadable(() => import(/* webpackChunkName: "GraphqlWyngStaticPage" */ '../GraphqlWyngStaticPage/GraphqlWyngStaticPage'));
const LoadableGraphqlRadioPage = loadable(() => import(/* webpackChunkName: "GraphqlRadioPage" */ '../GraphqlRadioPage/GraphqlRadioPage'));
const LoadableRadioOfferResultsPage = loadable(() => import(/* webpackChunkName: "RadioOfferResultsPage" */ '../GraphqlRadioPage/RadioOfferResults/RadioOfferResultsPage'));
const LoadableSympathyArticlePage = loadable(() => import(/* webpackChunkName: "SympathyArticlePage" */ '../SympathyComponents/SympathyArticlePage/SympathyArticlePage'));
const LoadableSympathyPagesBuilder = loadable(() => import(/* webpackChunkName: "SympathyPagesBuilder" */ '../SympathyComponents/SympathyPagesBuilder'));
const LoadableRecipeEntry = loadable(() => import(/* webpackChunkName: "GraphqlRecipeEntry" */ '../GraphqlRecipeEntry/GraphqlRecipeEntry'));
const LoadableHtmlSitemapContainer = loadable(() => import(/* webpackChunkName: "HtmlSitemapContainer" */ '../HtmlSitemap/HtmlSitemapContainer'));
const LoadableGraphql404ErrorContainer = loadable(() => import(/* webpackChunkName: "Graphql404ErrorContainer" */ '../Graphql404Error/Graphql404ErrorContainer'));
const LoadableGraphqlContactUsPage = loadable(() => import(/* webpackChunkName: "GraphqlContactUsPage" */ '../ContactUsPage/GraphqlContactUsPageContainer'));
const LoadableTemplateStorePage = loadable(() => import(/* webpackChunkName: "TemplateStorePage" */ '../TemplateStorePage/TemplateStorePageContiner'));
const LoadableHostedDinnersContainer = loadable(() => import(/* webpackChunkName: "HostedDinners" */ '../Template_HostedDinners/HostedDinners'));
const LoadableSmartGiftShopPage = loadable(() => import(/* webpackChunkName: "SmartGiftShopPage" */ '../SmartGiftShopPage/SmartGiftShopPage'));
const LoadablePromoCodePage = loadable(() => import(/* webpackChunkName: "PromoCodePage" */ '../PromoCodePage/PromoCodePage'));
const LoadableCustomerServiceContainer = loadable(() => import(/* webpackChunkName: "CustomerServiceContainer" */ '../CustomerService/CustomerServiceContainer'));
const LoadableSubscriptionPage = loadable(() => import(/* webpackChunkName: "SubscriptionGraphqlContainer" */  '../SubscriptionPageV2/SubscriptionGraphqlContainer'));
const LoadableGiftFinderResultBuilder = loadable(() => import(/* webpackChunkName: "GiftFinderResultBuilder" */ '../GiftFinderResultPage/GiftFinderResultBuilder'));
/*  Component Intent
- This component takes pageData from <CoreBrowsingContainer />
- the two indicators to determine a page are type and contentType
e.g. A collection page has a { type: category, contentType: standard_collections }
** This is how to determine what page is going to be built

Usage
- To add a component to CoreBrowsing:
    - Apply checks for type and contentType and load the container that will make the Grapqhl query
*/

const CoreBrowsingBuilder = ({
    brand, pageData, is404, productUnavailableName,
}) => {
    const { pathname, search } = useLocation();
    const history = useHistory();
    const brandDomainName = useSelector(getBrandName);
    const brandIdentifier = useSelector(getBrandIdentifier);
    const allABtests = useSelector(getActiveABTests);
    const ffAbTestRedirectEnabled = useSelector((state) => getFeatureFlag('is-optimize-redirect-test-enabled')(state));
    const isCardIsleTemplate = useSelector(getFeatureFlag('is-card-isle-template'));
    const activeRedirectABTests = ffAbTestRedirectEnabled ? getRedirectAbTest(allABtests)?.length : 0;
    const page = { path: pathname, search, ...pageData };
    useStyleLinkTag(`https://cdn1.${brandDomainName}.com/wcsstore/${brandIdentifier}/css/${process.env.NODE_ENV}/components/base/base.vars.css`);

    useEffect(() => {
        if (ffAbTestRedirectEnabled) {
            const redirectAbTest = getRedirectAbTest(allABtests);
            if (redirectAbTest?.length > 0) {
                redirectAbTesting({ history, redirectAbTest, pathname });
            }
        }

        if (typeof window !== 'undefined') {
            window.pageType = page?.type || '';
            window.pageUrl = pathname || '';
        }
    }, [pathname, activeRedirectABTests]);
    if (mbpUtil.getEnv('REACT_APP_MBP_LOGGER_CONSOLE') === 'DEBUG') {
        console.log('CoreBrowsingBuilder page ---->', page);
    }

    // Excluded Category paths
    // TODO: Brittle implementation
    const excludedCategoryPaths = [];

    // First: Vanity
    // TODO: This should be handled server-side
    // RedirectWithStatus will give universalLoader context.url and context.status
    if (page?.vanity) {
        const { bannerCode = '', url = '', redirectType = '' } = page.vanity;
        const bannerCodeUrl = page.bannerCode?.content?.entries?.[0]?.url;
        if (bannerCode && url && redirectType && bannerCodeUrl) {
            // example of vanity.url /refer.do?r=tmobile18f&utm_medium=bgs&utm_source=tmobile&utm_campaign=specoffer-web_email&utm_content=poliveto&utm_term=20d
            const pageSearchStr = url.split('?')[1];
            return (
                <PageErrorBoundary boundaryName="Redirect Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                    <RedirectWithStatus
                        to={`${bannerCodeUrl}?${pageSearchStr}`}
                        status={redirectType}
                    />
                </PageErrorBoundary>
            );
        }
        if (url && redirectType) {
            return (
                <PageErrorBoundary boundaryName="Redirect Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                    <RedirectWithStatus to={url} status={redirectType} />
                </PageErrorBoundary>
            );
        }
    }
    // CARD ISLE - based on Universal Lander
    if (isCardIsleTemplate && (page.path === '/send-greeting-cards' || page.path === '/greeting-cards-and-gift-cards')) {
        return (
            <PageErrorBoundary boundaryName="Card Isle Template Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableCardIsleTemplate page={{ ...page, pageName: 'Universal lander', name: 'Universal lander' }} brand={brand} />
            </PageErrorBoundary>
        );
    }
    // Universal Lander
    if (page.type === 'universal-lander') {
        return (
            <PageErrorBoundary boundaryName="Universal Lander Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlUniversalLander page={{ ...page, pageName: 'Universal lander', name: 'Universal lander' }} brand={brand} />
            </PageErrorBoundary>
        );
    }
    // Gift Finder Result Page
    if (page.path === '/perfect-gifts') {
        return (
            <PageErrorBoundary boundaryName="Gift Finder Result Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGiftFinderResultBuilder page={{
                    ...page, type: 'perfect-gifts', pageName: 'Perfect gifts', name: 'Perfect gifts',
                }}
                />
            </PageErrorBoundary>
        );
    }
    // Home
    if (page.type === 'home' || page.path === '/ProcessExpressRequest') {
        return (
            <PageErrorBoundary boundaryName="Home Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableHomePage page={page} />
            </PageErrorBoundary>
        );
    }

    // Department
    if (page.type === 'department') {
        return (
            <PageErrorBoundary boundaryName="Department Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlDepartmentPage page={page} />
            </PageErrorBoundary>
        );
    }

    // Recipe
    if (page.type === 'content' && page.contentType === 'recipe_lander') {
        return (
            <PageErrorBoundary boundaryName="Recipe Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlRecipePage page={page} />
            </PageErrorBoundary>
        );
    }

    // Club
    if (page.contentType === 'club_lander') {
        return (
            <PageErrorBoundary boundaryName="Club Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlClubPage page={page} />
            </PageErrorBoundary>
        );
    }

    // Coupon Page
    if (page.contentType === 'coupon_page') {
        return (
            <PageErrorBoundary boundaryName="Coupon Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableCouponPageContainer page={{
                    ...page, type: 'coupon_page', pageName: 'Coupon Page', name: 'Coupon Page',
                }}
                />
            </PageErrorBoundary>
        );
    }

    // Recipe Category Lander Page
    if (page.contentType === 'recipe_category_lander') {
        // eslint-disable-next-line no-param-reassign
        page.type = 'content-page'; // will remove after CS sending correct value
        return (
            <PageErrorBoundary boundaryName="Recipe_Category Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlRecipeCategoryLanderPage page={page} brand={brand} currRecipePath={page.path} />
            </PageErrorBoundary>
        );
    }

    if (page.contentType === 'contact_us') {
        return (
            <PageErrorBoundary boundaryName="Contact Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlContactUsPage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    // Category Template
    if (page.type === 'category-template' || page.contentType === 'template_page_sales' || page.contentType === 'template_page_partner_image_carousels') {
        return (
            <PageErrorBoundary boundaryName="Template Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlTemplatePageContainer pageData={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    if (page.type === 'content' && page.contentType === 'template_page_smart_gift_shop') {
        return (
            <PageErrorBoundary boundaryName="Template Page Smart Gift Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableSmartGiftShopPage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }
    if (page.type === 'content' && page.contentType === 'template_page_promo_codes') {
        return (
            <PageErrorBoundary boundaryName="Template Page Promo Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadablePromoCodePage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    // Contents
    if (isContentPageRoute({ page })) {
        if (page.type === 'notfound') {
            // eslint-disable-next-line no-param-reassign
            page.type = 'content';
        }
        return (
            <PageErrorBoundary boundaryName="Content Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableContentPageBuilder page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    // Connection Template
    if (page.type === 'content' && page.contentType === 'template_page_connection_collections') {
        return (
            <PageErrorBoundary boundaryName="Connect Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableConnectPage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    // Category
    if (page.type === 'category' && !excludedCategoryPaths.includes(page.path)) {
        return (
            <PageErrorBoundary boundaryName="Category Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlCategoryPageContainer page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    // Seo Article Template
    if (page.type === 'content' && page.contentType === 'template_page_seo_articles') {
        return (
            <PageErrorBoundary boundaryName="SEO_Articles Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableSeoArticlesPage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    if (page.contentType === 'passport') {
        return (
            <PageErrorBoundary boundaryName="Passport Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadablePassportPageContainer page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    // Product
    if (page.type === 'product' && page.contentType === 'standard_product') {
        if (
            is404 // SSR
            || (typeof window !== 'undefined' && window.is404) // Client
        ) {
            // render 404 UI
            return (
                <PageErrorBoundary boundaryName="Product_Not_Available Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                    <LoadableProductNotAvailableContainer brand={brand} productUnavailableName={productUnavailableName || (typeof window !== 'undefined' && window.productUnavailableName)} code={page.code} />
                </PageErrorBoundary>
            );
        }
        return (
            <PageErrorBoundary boundaryName="Product Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlProductPageContainer page={page} brand={brand} key={typeof window !== 'undefined' && window.location.pathname} />
            </PageErrorBoundary>
        );
    }

    // Content GFB
    if (page.type === 'content-page') {
        return (
            <PageErrorBoundary boundaryName="Graphql_Content Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlContentPage page={page} />
            </PageErrorBoundary>
        );
    }

    // TODO: Move us to Checkout VVVVV
    if (page.path === '/add-ons') {
        return (
            <PageErrorBoundary boundaryName="AddOns Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableAddOnsABContainer page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }
    if (page.path === '/wrap-up') {
        return (
            <PageErrorBoundary boundaryName="WrapUp Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableWrapUpABContainer page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }
    // TODO: Move us to Checkout ^^^^^

    // Connection Communities Page
    if (page.type === 'content' && page.contentType === 'template_page_wisdo') {
        return (
            <PageErrorBoundary boundaryName="Connection_Communities Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableConnectionCommunitiesPage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }
    // Ecards Page
    if (page.type === 'content' && page.contentType === 'template_page_ecards') {
        return (
            <PageErrorBoundary boundaryName="ECards Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableEcardsPage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }
    // Delayed Ecards Page
    if (page.type === 'content' && page.contentType === 'template_page_e_cards') {
        return (
            <PageErrorBoundary boundaryName="ECards Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableEcardsPage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }
    // Wyng Static Page
    if (page.contentType === 'template_page_wyng') {
        return (
            <PageErrorBoundary boundaryName="Wyng_Static Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlWyngStaticPage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    // Radio Interstitial flow
    // TODO: Use as model for correctly defining content pages in CMS (type AND subType)
    if (page.type === 'content' && page.contentType === 'radio_host_form') {
        return (
            <PageErrorBoundary boundaryName="Radio Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableGraphqlRadioPage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }
    // TODO: ???? (Use route params to define elements - /radio-offers-results/:type)
    if (page.path?.split('/')[1] === 'radio-offers-results') {
        return (
            <PageErrorBoundary boundaryName="Radio_Offer_Results Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableRadioOfferResultsPage page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    // Article Template Page
    if (page.contentType === 'template_page_articles') {
        return (
            <PageErrorBoundary boundaryName="Sympathy_Article Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableSympathyArticlePage page={page} />
            </PageErrorBoundary>
        );
    }
    // TODO: Move to content page routes
    if (page.path?.includes('Sympathy-') || page.path?.includes('sympathy-') || page.path?.includes('/customer-testimonials-sympathy')) {
        if (page.type === 'notfound') { // for static pages
            // eslint-disable-next-line no-param-reassign
            page.type = 'category';
        }
        return (
            <PageErrorBoundary boundaryName="Sympathy Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableSympathyPagesBuilder page={page} />
            </PageErrorBoundary>
        );
    }
    if (page.contentType === 'recipe_entry') {
        // eslint-disable-next-line no-param-reassign
        page.type = 'content-page'; // will remove after CS sending correct value
        return (
            <PageErrorBoundary boundaryName="Recipe_Entry Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableRecipeEntry page={page} />
            </PageErrorBoundary>
        );
    }

    if (page.contentType === 'question_answer_list') {
        return (
            <FrequentlyAskedQuestions queryTitle="Frequently Asked Questions" />
        );
    }

    //  Template Store Page
    if (page.contentType === 'template_page_store') {
        return <LoadableTemplateStorePage page={page} />;
    }

    // HTML site map
    if (page.contentType === 'site_map') {
        return (
            <PageErrorBoundary boundaryName="Sitemap Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableHtmlSitemapContainer page={page} brand={brand} />
            </PageErrorBoundary>
        );
    }

    // Hosted dinners
    if (page.contentType === 'hosted_dinners_event') {
        return (
            <PageErrorBoundary boundaryName="Hosted Dinners Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableHostedDinnersContainer path={page.path} />
            </PageErrorBoundary>
        );
    }
    // Hosted dinners
    if (page.contentType === 'template_page_customer_service' && page.type === 'content') {
        return (
            <PageErrorBoundary boundaryName="Template  Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableCustomerServiceContainer page={page} />
            </PageErrorBoundary>
        );
    }

    if (page.contentType === 'subscriptions_lander') {
        return (
            <PageErrorBoundary boundaryName="Subscription Lander Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                <LoadableSubscriptionPage page={page} />
            </PageErrorBoundary>
        );
    }

    // Undefined in CMS
    if (page.type === 'notfound') {
        // is404(SSR) and window.is404(client) will only be available through SSR when page not found
        // during local development(local:3000) this page will render blank please test in SSR
        if (
            is404 // SSR
            || (typeof window !== 'undefined' && window.is404) // Client
            // || page.path.indexOf('searchterm') === -1
        ) {
            // render 404 UI
            return (
                <PageErrorBoundary boundaryName="404 Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
                    <LoadableGraphql404ErrorContainer brand={brand} page={page} />
                </PageErrorBoundary>
            );
        }
        // when ssr hits this it will give context.url and context.status data (universalLoader.js)
        return <RedirectWithStatus to={page.path} status="404" />;
    }

    return (
        <PageErrorBoundary boundaryName="404 Page Error Boundary" errorMessage="Something unexpected occurred, please try again.">
            <LoadableGraphql404ErrorContainer brand={brand} page={page} />
        </PageErrorBoundary>
    );
};

CoreBrowsingBuilder.propTypes = {
    pageData: object.isRequired,
    brand: object.isRequired,
    is404: bool,
    productUnavailableName: string,
};

CoreBrowsingBuilder.defaultProps = {
    is404: false,
    productUnavailableName: '',
};

const enhance = compose(
    trackPage(),
);

export default enhance(CoreBrowsingBuilder);
